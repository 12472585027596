import React from "react"
import ThirdStepFirstTab from "../components/thirdStepFirstTab/ThirdStepFirstTab";

const Step3 = () => {
	return (
		<>
			<ThirdStepFirstTab/>
		</>
	)
};

export default Step3
