import React from "react"
import styled from "styled-components"

const SingleRadioButtonWrapper = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	max-width: ${({isMaxWidthBigger}) => isMaxWidthBigger ? "100px" : "80px"};
	margin-right: 20px;
	@media(min-width: 414px) {
		margin-right: 4px;
	}
	@media(min-width: 500px) {
		margin-right: 16px;
	}
	@media(min-width: 768px) {
		margin-right: 18px;
	}
	@media(min-width: 800px) {
		margin-right: 20px;
	}
`;

const SingleRadioButton = styled.div`
	color: ${({theme}) => theme.colors.formLightGrey};
	width: 55px;
	height: 55px;
	top: 4px;
	border-radius: 50%;
	border: 1px solid ${({ theme }) => theme.colors.formLightGreen};
	background-color: ${({theme}) => theme.colors.formTransparent};
	position: relative;
	cursor: pointer;
	display: flex;
	flex-direction: column;
	justify-content: center;
	color: ${({theme}) => theme.colors.formDarkGreen};
	font-weight: ${({theme}) => theme.fontWeight.bold};
    font-size: 22px;
    line-height: 28px;
    margin-bottom: 15px;
    @media(min-width: 768px) {
    	width: 60px;
    	height: 60px;
    }
	&:hover {
		border: 1px solid ${({ theme }) => theme.colors.formDarkGreen};
	}
	@media(min-width: 1280px) {
		margin-bottom: 0;
	}
	&.active {
		border: 2px solid ${({ theme }) => theme.colors.formDarkOrange};
		box-shadow: 0 3px 5px 0 rgba(0,0,0,0.09) !important;
		background-color: ${({theme}) => theme.colors.formTileActiveBackgroundOrange};
	}
`;

const RadioButtonLabel = styled.p`
	color: ${({theme}) => theme.colors.formDarkGreen};
	font-weight: ${({theme}) => theme.fontWeight.bold};
	font-size: 22px;
	line-height: 28px;
	cursor: pointer;
	display: flex;
	flex-direction: row;
	justify-content: center;
`;

const RadioButton = ({ amountName, id, activeId, onClick, defaultId, isMaxWidthBigger }) => {
	
	return (
		<SingleRadioButtonWrapper isMaxWidthBigger={isMaxWidthBigger} key={id} onClick={() => onClick(id)}>
			<SingleRadioButton className={((activeId || defaultId) === id) ? "active" : ""}>
				<RadioButtonLabel>{amountName}</RadioButtonLabel>
			</SingleRadioButton>
		</SingleRadioButtonWrapper>
	)
};

export default RadioButton;
