import React, {useEffect} from "react"
import styled from "styled-components"
import {Header} from "../common/Header";
import {CategoryHeader} from "../common/CategoryHeader";
import PreviousStepButton from "../common/PreviousStepButton";
import NextStepButton from "../common/NextStepButton";
import CustomSelect from "../common/CustomSelect";
import {useDispatch, useSelector} from "react-redux"
import {fetchAmountAction} from "../../state/amount";
import {fetchInsuranceAgenciesAction} from "../../state/insuranceAgencies";
import {setFormValueAction} from "../../state/form";
import {ContentWrapper} from "../common/ContentWrapper";
import RadioButton from "../common/SingleRadioButton";
import {ErrorMessage, Formik} from "formik";
import {navigate} from "gatsby";
import {ErrorMessageText, GreenHint, TabWrapper} from "../firstStepFirstTab/FirstStepFirstTab";
import {NavigationButtonsWrapper} from "../common/NavigationButtonsWrapper";
import {setThirdStepFilled} from "../../state/setPagesAreFilled"

const RadioButtonsWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	flex-wrap: wrap;
	margin-bottom: 10px;
	padding-right: 10%;
	@media(min-width: 390px) {
		padding-right: 16%;
	}
	@media(min-width: 414px) {
		padding-right: 0;
	}
	@media(min-width: 768px) {
		padding-right: 10%;
	}
	@media(min-width: 1024px) {
		margin-top: 15px;
	}
	@media(min-width: 1920px) {
		margin-top: 0;
	}
`

const HeaderAndRadioButtonsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	margin: 20px 0 5px;
	@media(min-width: 1280px) {
		margin: 30px 0 15px;
	}
`

const ThirdStepFirstTab = () => {
	const dispatch = useDispatch()
	const formReducer = useSelector(state => state.form)
	const amountReducer = useSelector(state => state.amount)
	const insuranceAgenciesReducer = useSelector(state => state.insuranceAgencies)

	useEffect(() => {
		dispatch(fetchAmountAction())
		dispatch(fetchInsuranceAgenciesAction())
	}, [])

	return (
		<TabWrapper>
			<Header className="tablet-hidden">Historia ubezpieczenia</Header>
			<GreenHint className="tablet-hidden">wypełnij formularz i porównaj ceny</GreenHint>
			<ContentWrapper>
				<Formik
					initialValues={{
						insuranceAgencyOC: formReducer.insuranceAgencyOC,
						insuranceAgencyOCLabel: formReducer.insuranceAgencyOCLabel,
						yearsOCWasBought: formReducer.yearsOCWasBought,
						OCDamageAmount: formReducer.OCDamageAmount,
						insuranceAgencyAC: formReducer.insuranceAgencyAC,
						insuranceAgencyACLabel: formReducer.insuranceAgencyACLabel,
						yearsACWasBought: formReducer.yearsACWasBought,
						ACDamageAmount: formReducer.ACDamageAmount,
					}}
			        onSubmit={(values, {setSubmitting}) => {
				        setSubmitting(false);
				        dispatch(setFormValueAction("insuranceAgencyOC", values.insuranceAgencyOC))
				        dispatch(setFormValueAction("insuranceAgencyOCLabel", values.insuranceAgencyOCLabel))
				        dispatch(setFormValueAction("yearsOCWasBought", values.yearsOCWasBought))
				        dispatch(setFormValueAction("OCDamageAmount", values.OCDamageAmount))
				        dispatch(setFormValueAction("insuranceAgencyAC", values.insuranceAgencyAC))
				        dispatch(setFormValueAction("insuranceAgencyACLabel", values.insuranceAgencyACLabel))
				        dispatch(setFormValueAction("yearsACWasBought", values.yearsACWasBought))
				        dispatch(setFormValueAction("ACDamageAmount", values.ACDamageAmount))
				        navigate('/step-4')
				        dispatch(setThirdStepFilled(true))
			        }}>
					{({values, errors, isSubmitting, touched, handleChange, handleBlur, handleSubmit, setFieldValue}) => (
						<form onSubmit={handleSubmit}>
							<CustomSelect selectLabel="Poprzedni ubezpieczyciel OC"
							              placeholder={"Wybierz ubezpieczyciela"}
							              name="insuranceAgencyOC"
							              options={insuranceAgenciesReducer.data}
							              isLoading={insuranceAgenciesReducer.loading}
							              isFirst={true}
							              selectedValue={values.insuranceAgencyOC}
							              onChange={(val) => {
							                setFieldValue("insuranceAgencyOC", val)
							                setFieldValue("insuranceAgencyOCLabel", val.label)}}/>
							<ErrorMessageText><ErrorMessage name="insuranceAgencyOC"/></ErrorMessageText>
							<HeaderAndRadioButtonsWrapper>
								<CategoryHeader className="radio-buttons">Liczba lat kupowania polis OC</CategoryHeader>
								<RadioButtonsWrapper>
									{amountReducer.data.map(({ label, value }) => (
									<RadioButton id={value}
									             key={value}
									             name="yearsOCWasBought"
									             activeId={values.yearsOCWasBought}
									             amountName={label}
									             onClick={(val) => setFieldValue("yearsOCWasBought", val)}/>
								))}
								</RadioButtonsWrapper>
							</HeaderAndRadioButtonsWrapper>
							<ErrorMessageText><ErrorMessage name="yearsOCWasBought"/></ErrorMessageText>
							<HeaderAndRadioButtonsWrapper>
								<CategoryHeader className="radio-buttons">Ilość szkód OC w ostatnim roku</CategoryHeader>
								<RadioButtonsWrapper>
								{amountReducer.data.map(({ label, value }) => (
									<RadioButton id={value}
									             key={value}
									             activeId={values.OCDamageAmount}
									             amountName={label}
									             onClick={(val) => setFieldValue("OCDamageAmount", val)}/>
								))}
								</RadioButtonsWrapper>
							</HeaderAndRadioButtonsWrapper>
							<ErrorMessageText><ErrorMessage name="OCDamageAmount"/></ErrorMessageText>
							<CustomSelect selectLabel="Poprzedni ubezpieczyciel AC"
							              placeholder={"Wybierz ubezpieczyciela"}
							              options={insuranceAgenciesReducer.data}
							              name="insuranceAgencyAC"
							              isLoading={insuranceAgenciesReducer.loading}
							              selectedValue={values.insuranceAgencyAC}
							              onChange={(val) => {
							                setFieldValue("insuranceAgencyAC", val)
							                setFieldValue("insuranceAgencyACLabel", val.label)
							              }}/>
							<ErrorMessageText><ErrorMessage name="insuranceAgencyAC"/></ErrorMessageText>
							<HeaderAndRadioButtonsWrapper>
								<CategoryHeader className="radio-buttons">Liczba lat kupowania polis AC</CategoryHeader>
								<RadioButtonsWrapper>
									{amountReducer.data.map(({ label, value }) => (
									<RadioButton id={value}
									             key={value}
									             activeId={values.yearsACWasBought}
									             amountName={label}
									             onClick={(val) => setFieldValue("yearsACWasBought", val)}/>
								))}
								</RadioButtonsWrapper>
							</HeaderAndRadioButtonsWrapper>
							<ErrorMessageText><ErrorMessage name="yearsACWasBought"/></ErrorMessageText>
							<HeaderAndRadioButtonsWrapper>
								<CategoryHeader className="radio-buttons">Ilość szkód AC w ostatnim roku</CategoryHeader>
								<RadioButtonsWrapper>
									{amountReducer.data.map(({ label, value }) => (
									<RadioButton id={value}
									             key={value}
									             activeId={values.ACDamageAmount}
									             amountName={label}
									             onClick={(val) => setFieldValue("ACDamageAmount", val)}/>
								))}
								</RadioButtonsWrapper>
							</HeaderAndRadioButtonsWrapper>
							<ErrorMessageText><ErrorMessage name="ACDamageAmount"/></ErrorMessageText>
							<NavigationButtonsWrapper>
								<PreviousStepButton type="button" isVisible={true} textBtn={"Poprzedni krok"} to="/step-2"/>
								<NextStepButton type="submit" isVisible={true} textBtn={"DALEJ"}
								                isDisabled={isSubmitting}/>
							</NavigationButtonsWrapper>
						</form>)}
				</Formik>
			</ContentWrapper>
		</TabWrapper>
	)
};

export default ThirdStepFirstTab;